import React from "react"
import { graphql, Link } from "gatsby"

import Layout, { Section } from "../components/Layout"
import PageImage from "../components/PageUnsplashImage"
import { ContactForm } from "../components/ContactForm"
import { HTMLContent } from "../components/Content"

const Project = ({ title, link, description, image }) => {
  return (
    <div className="card">
      <div className="card-image">
        <figure className="image is-4by3">
          <img src={image} alt={title} />
        </figure>
      </div>
      <div className="card-content">
        <div className="content">
          <Link to={link} target={link.startsWith("http") && "_blank"}>
            <h2 className="title is-link">{title}</h2>
          </Link>
          <HTMLContent content={description} />
        </div>
      </div>
    </div>
  )
}

export const Template = ({ title, content, image, imageCredit, projects, location }) => {
  const formVariation = location.pathname.replace("/", "")

  return (
    <div>
      <PageImage src={image} credit={imageCredit}>
        <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
          {title}
        </h1>
      </PageImage>

      <Section>
        <div className="columns">
          {projects.map((p) => (
            <div className="column flex is-4">
              <Project
                title={p.title}
                link={p.link}
                description={p.description}
                image={p.picture}
              />
            </div>
          ))}
        </div>
        <div className="mt-3">
          <HTMLContent className="content" content={content} />
          <ContactForm variation={formVariation} />
        </div>
      </Section>
    </div>
  )
}

const Page = ({ data, location }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <Template
        location={location}
        title={post.frontmatter.title}
        content={post.html}
        projects={post.frontmatter.projects}
        image={post.frontmatter.image}
        imageCredit={post.frontmatter.imageCredit}
      />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query Page($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        imageCredit {
          author
          href
        }
        image
        projects {
          title
          description
          link
          picture
        }
      }
    }
  }
`
